import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState = {
    numDocImportato: '',
    numDocRicevuto: '',
    nomeDocumento: '',
    tipoDocumento: '',
    bollo: {
        includiBolloAutomaticamente: false,
        includiBolloNelTotale: false,
        valoreBollo: 0
    },
    data: moment().format('YYYY-MM-DD'),
    idSezionale: '',
    numero: 0,
    collaboratoreId: 0,
    magazzinoId: 0,
    listinoId: 0,
    anagraficaCliente: {},
    anagraficaFornitore: {},
    infoTrasporto: {
        /*      vettoreDenominazione: '',
             mezzoTrasporto: '',
             pesoNetto: '',
             pesoLordo: '',
             numeroColli: '',
             dataInizioTrasporto: moment().format('YYYY-MM-DD'),
             dataOraRitiro: moment().format('YYYY-MM-DDTHH:mm'),
             dataOraConsegna: moment().format('YYYY-MM-DDTHH:mm'),
             causaleTrasporto: '',
             descrizione: '' */
    },
    formatoTrasmissione: 'FPR12',
    prodotti: [],
    bolloCliente: false,
    includeSpedizione: false,
    regimeFiscaleId: 0,
    indirizzoSpedizione: {},
    causale: '',
    note: '',
    dettagliPagamentoSdi: {
        puntoCassa: null,
        tipoPagamento: null,
        scadenze: []
    },
    isSameAddress: false,
    subtotaleDocumento: 0,
    scontoTotale: 0,
    totale: 0,
    totaleDovuto: 0,
    totaleIVA: 0,
    aliquotaIva: {},
    contributoPrevidenziale: {},
    ritenuta: {},
    allegati: [],
    riferimenti: []
}

const docFiscaleSlice = createSlice({
    name: 'docFiscale',
    initialState,
    reducers: {
        DOC_FISCALE_VALORIZZATO: (state, { payload }) => {
            return { ...state, ...payload }
        },
        SET_FIELD_DOC: (state, { payload }) => {
            state[payload.field] = payload.value
        },
        ADD_CUSTOM_PRODUCT: (state, { payload }) => {
            state.prodotti.push(payload)
        },
        ADD_EXISTING_PRODUCT: (state, { payload }) => {
            payload.forEach(newProduct => {
                const productExists = state.prodotti.some(product => product.articoloId === newProduct.articoloId)
                if (!productExists) {
                    state.prodotti.push(newProduct)
                }
            })
        },
        UPDATE_GRID_ROW: (state, { payload }) => {
            const { idInterno, data } = payload
            const indexRow = state.prodotti.findIndex((prodotto) => prodotto.idInterno === idInterno)
            if (indexRow !== -1) {
                state.prodotti[indexRow] = data
            }
        },
        DELETE_PRODUCTS: (state, { payload }) => {
            state.prodotti = state.prodotti.filter(prodotto => !payload.includes(prodotto.idInterno))
        },
        CALCOLA_TOTALI: (state) => {
            let totalPriceRows = 0
            let totalDiscount = 0
            let totalIvaComplessivo = 0
            let contributoPrevidenziale = 0
            let importoRitenuta = 0
            const aliquoteIvaMap = new Map()

            let hasAliquotaIvaGreaterThanZero = false // Initialize flag

            state.prodotti.forEach((prodotto) => {
                const quantita = parseFloat(prodotto.quantita) || 0
                const prezzoUnitario = parseFloat(prodotto.prezzoUnitario?.replace(",", ".") || 0)
                const scontoPerc = parseFloat(prodotto.sconto1?.replace(",", ".") || 0) // Discount percentage
                /* eslint-disable-next-line */
                const discountFactor = 1 - (scontoPerc / 100) // Calculate discount factor

                // Apply discount to unit price
                const prezzoUnitarioScontato = prezzoUnitario * discountFactor
                const prezzoTotale = prezzoUnitarioScontato * quantita // Calculate total price after discount

                totalPriceRows += prezzoTotale // Add to total price rows

                // Calculate the total discount amount
                const discountAmount = (prezzoUnitario - prezzoUnitarioScontato) * quantita // Correct discount amount
                totalDiscount += discountAmount // Add to total discount

                const imponibile = prezzoTotale // Imponibile is the discounted total price

                if (prodotto.aliquotaIva?.value) {
                    const aliquotaIVA = parseFloat(prodotto.aliquotaIva.value?.replace(",", ".") || 0)

                    if (aliquotaIVA > 0) {
                        hasAliquotaIvaGreaterThanZero = true // Set flag to true if aliquota IVA > 0
                    }

                    const imposta = (imponibile * aliquotaIVA) / 100
                    const aliquotaKey = aliquotaIVA.toFixed(2)

                    if (!aliquoteIvaMap.has(aliquotaKey)) {
                        aliquoteIvaMap.set(aliquotaKey, {
                            imponibileImporto: imponibile.toFixed(2),
                            aliquotaIva: aliquotaKey,
                            natura: prodotto.aliquotaIva.natura || "",
                            imposta: imposta.toFixed(2),
                            esigibilitaIva: state.esigibilitaIva || "",
                            riferimentoNormativo: prodotto.aliquotaIva.text || "",
                            ivaContrPrev: false
                        })
                    } else {
                        const data = aliquoteIvaMap.get(aliquotaKey)
                        data.imponibileImporto = (parseFloat(data.imponibileImporto) + imponibile).toFixed(2)
                        data.imposta = (parseFloat(data.imposta) + imposta).toFixed(2)
                    }

                    totalIvaComplessivo += imposta
                }
            })

            // Calculate the contributo previdenziale if it is included
            if (state.contributoPrevidenziale?.includeCassa) {
                const percentuale = parseFloat(
                    state.contributoPrevidenziale.aliquotaCassa?.replace(",", ".") || "0"
                )
                contributoPrevidenziale = (totalPriceRows * percentuale) / 100
                state.contributoPrevidenziale.imponibileContributoPrevidenziale = totalPriceRows.toFixed(2)

                const primoProdotto = state.prodotti[0]
                const aliquotaIvaContributo = parseFloat(primoProdotto?.aliquotaIva?.value?.replace(",", ".") || "0")
                const naturaIvaContributo = primoProdotto?.aliquotaIva?.natura || ""

                const impostaContributoPrevidenziale = (contributoPrevidenziale * aliquotaIvaContributo) / 100
                totalIvaComplessivo += impostaContributoPrevidenziale

                aliquoteIvaMap.set(`Contributo-${aliquotaIvaContributo.toFixed(2)}`, {
                    imponibileImporto: contributoPrevidenziale.toFixed(2),
                    aliquotaIva: aliquotaIvaContributo.toFixed(2),
                    natura: naturaIvaContributo,
                    imposta: impostaContributoPrevidenziale.toFixed(2),
                    esigibilitaIva: state.esigibilitaIva || "",
                    riferimentoNormativo: primoProdotto?.aliquotaIva?.text || "",
                    ivaContrPrev: true
                })
            }

            // Calculate the withholding tax (ritenuta) if it is included
            if (state.ritenuta?.includeRitenuta) {
                const percentuale = parseFloat(
                    state.ritenuta.aliquotaRitenuta?.replace(",", ".") || '0.00'
                )
                importoRitenuta = state.prodotti.reduce((acc, prodotto) => {
                    return prodotto.ritenuta ? acc + parseFloat(prodotto.prezzoTotale?.replace(",", ".")) : acc
                }, 0) * (percentuale / 100)
            }

            // Calculate the total and total due
            const totale = totalPriceRows + totalIvaComplessivo + contributoPrevidenziale
            const totaleDovuto = totale - importoRitenuta

            // Set calculated values to the state
            state.subtotaleDocumento = totalPriceRows.toFixed(2)
            state.scontoTotale = totalDiscount.toFixed(2)
            state.totaleIVA = totalIvaComplessivo.toFixed(2)
            state.contributoPrevidenziale.valoreContributoPrevidenziale = contributoPrevidenziale.toFixed(2)
            state.ritenuta.importoRitenuta = importoRitenuta.toFixed(2)
            state.totale = totale.toFixed(2)
            state.totaleDovuto = totaleDovuto.toFixed(2)

            state.aliquotaIva = {
                ivaTotale: totalIvaComplessivo.toFixed(2),
                aliquoteIva: Array.from(aliquoteIvaMap.values())
            }

            // Adjust state.bollo.includiBolloNelTotale based on the flags
            if (!hasAliquotaIvaGreaterThanZero) {
                if (state.bollo.includiBolloAutomaticamente) {
                    state.bollo.includiBolloNelTotale = true
                } else {
                    state.bollo.includiBolloNelTotale = state.bollo.includiBolloNelTotale
                }
            } else {
                state.bollo.includiBolloNelTotale = false
            }
        },
        SET_FIELD_RITENUTA: (state, { payload }) => {
            state.ritenuta[payload.field] = payload.value
        },
        SET_FIELD_CONTRIBUTO_PREVIDENZIALE: (state, { payload }) => {
            state.contributoPrevidenziale[payload.field] = payload.value
        },
        SET_FIELD_BOLLO_DIGITALE: (state, { payload }) => {
            state.bollo[payload.field] = payload.value
        },
        SET_FIELD_TRASPORTO: (state, { payload }) => {
            state.infoTrasporto[payload.field] = payload.value
        },
        AGGIORNA_RIFERIMENTI_CON_RIGHEGRID: (state, { payload }) => {
            // payload contiene l'array attuale di righeGrid
            const righeGridIds = payload.map(riga => riga.idInterno) // Assumiamo che ogni riga in righeGrid abbia un id univoco

            // Itera su ogni riferimento esistente
            const riferimentiAggiornati = state.riferimenti.map(riferimento => {
                // Filtra le linee di ogni riferimento mantenendo solo quelle presenti in righeGrid
                const lineeAggiornate = riferimento.linee.filter(linea => righeGridIds.includes(linea.idInterno))

                return {
                    ...riferimento,
                    linee: lineeAggiornate
                }
            })

            // Aggiorna i riferimenti nello stato con le linee filtrate
            state.riferimenti = riferimentiAggiornati
        },
        SET_FIELD_PAGAMENTI: (state, { payload }) => {
            state.dettagliPagamentoSdi[payload.field] = payload.value
        },
        ADD_SCADENZA: (state, { payload }) => {
            state.dettagliPagamentoSdi.scadenze.push(payload)
        },
        DELETE_SCADENZA: (state, { payload }) => {
            state.dettagliPagamentoSdi.scadenze.splice(payload, 1)
        },
        UPDATE_SCADENZA_FIELD: (state, { payload }) => {
            const { idInterno, subField, value } = payload
            const scadenza = state.dettagliPagamentoSdi.scadenze.find(row => row.idInterno === idInterno)
            if (scadenza) {
                scadenza[subField] = value
            }
        },
        RESET_SCADENZE: (state) => {
            state.dettagliPagamentoSdi.scadenze = []
        },
        RESET_DOC_FISCALE: () => initialState
    }
})

export const {
    DOC_FISCALE_VALORIZZATO,
    SET_FIELD_DOC,
    ADD_CUSTOM_PRODUCT,
    ADD_EXISTING_PRODUCT,
    DELETE_PRODUCTS,
    UPDATE_GRID_ROW,
    SET_FIELD_RITENUTA,
    SET_FIELD_CONTRIBUTO_PREVIDENZIALE,
    SET_FIELD_BOLLO_DIGITALE,
    SET_FIELD_TRASPORTO,
    AGGIORNA_RIFERIMENTI_CON_RIGHEGRID,
    SET_FIELD_PAGAMENTI,
    SET_TIPO_PAGAMENTO,
    ADD_SCADENZA,
    DELETE_SCADENZA,
    UPDATE_SCADENZA_FIELD,
    RESET_SCADENZE,
    CALCOLA_TOTALI,
    RESET_DOC_FISCALE
} = docFiscaleSlice.actions

export default docFiscaleSlice.reducer
