// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
function convertToCaslFormat(abilitiesArray) {
  return abilitiesArray.map(abilityString => {
    const firstSpaceIndex = abilityString.indexOf(' ') // Find the index of the first space
    const action = abilityString.substring(0, firstSpaceIndex) // Extract the action based on the first space
    const subject = abilityString.substring(firstSpaceIndex + 1) // Extract the subject as the rest of the string
    return { action, subject }
  })
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: {
      menu: [],
      permissions: [],
      token: '',
      organizations: [],
      puntiVendita: [],
      roles: []
    },
    activeAgency: {}
  },
  reducers: {
    LOGIN: (state, action) => {

      const permissions = convertToCaslFormat(action.payload.permissions.map(e => e.name))
      console.log('payload', action.payload)
      window.localStorage.setItem('userData', JSON.stringify({
        menu: action.payload.menu,
        permissions,
        token: action.payload.token,
        organizations: action.payload.organizations,
        roles: ['client', ...action.payload.roles.map(e => e.name)],
        persona: action.payload.persona,
        user: action.payload.user
      }))
      state.userData = {
        menu: action.payload.menu,
        permissions,
        token: action.payload.token,
        organizations: action.payload.organizations,
        roles: ['client', ...action.payload.roles.map(e => e.name)],
        persona: action.payload.persona,
        user: action.payload.user
      }
    },
    UPDATE_MY_INFO: (state, action) => {
      window.localStorage.setItem('userData', JSON.stringify({
        ...JSON.parse(window.localStorage.getItem('userData')),
        menu: action.payload.menu,
        permissions: convertToCaslFormat(action.payload.permissions.map(e => e.name)),
        organizations: action.payload.organizations,
        puntiVendita: action.payload.puntiVendita,
        roles: ['client', ...action.payload.roles.map(e => e.name)],
        persona: action.payload.persona,
        user: action.payload.user
      }))
      state.userData = {
        ...JSON.parse(window.localStorage.getItem('userData')),
        menu: action.payload.menu,
        permissions: convertToCaslFormat(action.payload.permissions.map(e => e.name)),
        organizations: action.payload.organizations,
        puntiVendita: action.payload.puntiVendita,
        roles: ['client', ...action.payload.roles.map(e => e.name)],
        persona: action.payload.persona,
        user: action.payload.user
      }
      if (action.payload.puntiVendita.length === 1) {
        state.activePuntoVendita = action.payload.puntiVendita[0]
        window.localStorage.setItem('activePuntoVendita', JSON.stringify(action.payload.puntiVendita[0]))
      }
    },
    SET_ACTIVE_AGENCY: (state, action) => {
      window.localStorage.setItem('activeAgency', JSON.stringify(action.payload))
      state.activeAgency = action.payload
      console.log('activeAgency', action.payload)
    },
    SET_ACTIVE_PUNTOVENDITA: (state, action) => {
      window.localStorage.setItem('activePuntoVendita', JSON.stringify(action.payload))
      state.activePuntoVendita = action.payload
    },
    LOGOUT: (state) => {
      state.userData = {
        menu: [],
        permissions: [],
        token: '',
        organizations: [],
        roles: [],
        user: {}
      }
      window.localStorage.removeItem('userData')
      window.localStorage.removeItem('activeAgency')
      window.localStorage.removeItem('activePuntoVendita')
    }
  }
})

export const { LOGIN, LOGOUT, UPDATE_MY_INFO, SET_ACTIVE_AGENCY, SET_ACTIVE_PUNTOVENDITA } = authSlice.actions

export default authSlice.reducer
