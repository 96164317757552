// ** React Imports
import { Suspense, lazy, useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
// ** Redux Imports
import { store } from './redux/store'
import { Provider, useSelector } from 'react-redux'

// ** Intl, CASL & ThemeColors Context
import { Ability } from '@casl/ability'
import { AbilityContext } from './utility/context/Can'
import { ThemeContext } from './utility/context/ThemeColors'

// ** ThemeConfig
import themeConfig from './configs/themeConfig'

// ** Toast
import { Toaster } from 'react-hot-toast'

// ** i18n
import './configs/i18n'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** Fake Database
import './@fake-db'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Hot Toast Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import '../node_modules/flatpickr/dist/flatpickr.css'

// ** Service Worker
import * as serviceWorker from './serviceWorker'
// ** Third Party Components
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// ** SYNCFUSION LICENSE
import { registerLicense, loadCldr } from '@syncfusion/ej2-base'
registerLicense('Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXZfcnRdRmlfUER+Vko=')
/* 'Mgo+DSMBaFt/QHRqVVhkXFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSH5XdkBiXX1YeHxURw==;Mgo+DSMBPh8sVXJ0S0J+XE9AdlRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Td0VnWXpfdHZTT2BaUA==;ORg4AjUWIQA/Gnt2VVhkQlFacl9JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRjXX9bcXBXQGleU0U=;ODcxMDg4QDMyMzAyZTM0MmUzMEJxTlZUUUx4NWZKZXdhT1BhcEJwcllhRkRuOEQ1YU9SZk5DWlNZMEdjTW89;ODcxMDg5QDMyMzAyZTM0MmUzMGRsTmxVVGFLSkcvVW9vMlZjNGhvRXJmQVhWdkpTOU1yKzEzWC9CYnVEbmc9;NRAiBiAaIQQuGjN/V0Z+WE9EaFtCVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUViWXtedXVRRGZVWER3;ODcxMDkxQDMyMzAyZTM0MmUzMGIxZE13Q0RpV2x3QlZRT1ZxcGhFME4rL2YvT0FJY0VyditVS3BuVzFQRmM9;ODcxMDkyQDMyMzAyZTM0MmUzMGlFZnFxbkM5bDN1cGlhWVJBRVJ3bzJrTEoyc0MxUXpUTXFzRG5lYmNjZVU9;Mgo+DSMBMAY9C3t2VVhkQlFacl9JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRjXX9bcXBXQWBZVUU=;ODcxMDk0QDMyMzAyZTM0MmUzMFpvNHdaL1VTcVNneVdndEREU09id3FvOHB1WFFLMWpNNXU0OWNpVlFDWm89;ODcxMDk1QDMyMzAyZTM0MmUzMFZOSTFiUzdIRFhXT3BrZE9oN3VTYThZZk44cyswVEprOUsxQk9YemozS0k9;ODcxMDk2QDMyMzAyZTM0MmUzMGIxZE13Q0RpV2x3QlZRT1ZxcGhFME4rL2YvT0FJY0VyditVS3BuVzFQRmM9 */
//import the loadCldr from ej2-base
loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/it/ca-gregorian.json'),
  require('cldr-data/main/it/numbers.json'),
  require('cldr-data/main/it/timeZoneNames.json')
)

const AbilityProvider = ({ children }) => {
  // Use Redux state to get abilities
  const userAbilities = useSelector((state) => state.auth.userData.permissions) || []
  const userAbilitiesFromLocalStorage = JSON.parse(localStorage.getItem('userData'))?.permissions || []
  const [ability, setAbility] = useState(new Ability([])) // Initialize with empty abilities
  useEffect(() => {
    const permissions = userAbilities.lenght > 0 ? userAbilities : userAbilitiesFromLocalStorage
    ability.update(permissions)
    setAbility(ability)
  }, [userAbilities, ability])

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
}

const MySwal = withReactContent(Swal)
// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

const container = document.getElementById('root')
const root = createRoot(container)

const navigatorOfflineIcon = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMC4wLWJldGEyIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIC0tPjxwYXRoIGQ9Ik0zOTguMSAyMzYuMWMzOC41NSAxMi4yNyA3My42MSAzMy41NiAxMDIuNCA2My4wNUM1MDMuNyAzMDIuNCA1MDcuOCAzMDQgNTEyIDMwNGM0LjAzMSAwIDguMDYyLTEuNSAxMS4xOS00LjU2MmM2LjMxMi02LjE1NiA2LjQzOC0xNi4zMSAuMjUtMjIuNjJjLTQ3Ljk2LTQ5LjA4LTExMS4xLTc4LjE3LTE3OS43LTgzLjY2TDM5OC4xIDIzNi4xek0zMjAgNjRjMTExLjkgMCAyMTguNSA0NC44NCAyOTIuNCAxMjNDNjE1LjUgMTkwLjMgNjE5LjggMTkyIDYyNCAxOTJjMy45MzggMCA3LjkwNi0xLjQzOCAxMS00LjM3NWM2LjQwNi02LjA2MiA2LjY4OC0xNi4xOSAuNjI1LTIyLjYyQzU1NS43IDgwLjQ3IDQ0MC43IDMyIDMxOS4xIDMyQzI2OC45IDMyIDIxOC45IDQxLjE5IDE3Mi4xIDU3LjY1bDI5LjkgMjMuNkMyMzkuOCA3MC4wNSAyNzkuNSA2NCAzMjAgNjR6TTQuMzY5IDE2NWMtNi4wNjIgNi40MzgtNS43ODEgMTYuNTYgLjYyNSAyMi42MkMxMS40MyAxOTMuNyAyMS41MyAxOTMuNCAyNy42MiAxODdjMTQuNzMtMTUuNTcgMzEuMDYtMjkuNDIgNDguMTctNDIuMjZsLTI1Ljg5LTIwLjQ0QzMzLjg2IDEzNi45IDE4LjQgMTUwLjIgNC4zNjkgMTY1ek0xMTYuNiAyNzYuOEMxMTAuNCAyODMuMSAxMTAuNSAyOTMuMyAxMTYuOCAyOTkuNGM2LjM3NSA2LjIxOSAxNi41IDYuMDMxIDIyLjYyLS4yNWMyMC4yNi0yMC43MyA0My43MS0zNy4yMSA2OS4xNy00OS41OWwtMjcuNjYtMjEuODRDMTU3LjUgMjQwLjggMTM1LjggMjU3LjIgMTE2LjYgMjc2Ljh6TTM0NC41IDM1Ni45Yy03LjU1OS0zLjE0MS0xNS44NC00LjkyNS0yNC41NC00LjkyNWMtMzUuMzUgMC02NCAyOC42Ni02NCA2NC4wMXMyOC42NiA2NC4wMSA2NCA2NC4wMXM2NC0yOC42NiA2NC02NC4wMWMwLTE0LjI5LTQuODM4LTI3LjM0LTEyLjc1LTM3Ljk5TDM0NC41IDM1Ni45ek0zMjAgNDQ4Yy0xNy42NCAwLTMyLTE0LjM2LTMyLTMyczE0LjM2LTMyIDMyLTMyczMyIDE0LjM2IDMyIDMyUzMzNy42IDQ0OCAzMjAgNDQ4ek0yNS45MiAzLjQyMmMtNi45MzgtNS40NTMtMTctNC4yNS0yMi40OCAyLjY0MWMtNS40NjkgNi45MzgtNC4yODEgMTcgMi42NDEgMjIuNDhsNjA4IDQ4MEM2MTcgNTEwLjkgNjIwLjUgNTEyIDYyMy4xIDUxMmM0LjczNCAwIDkuNDIyLTIuMDk0IDEyLjU4LTYuMDc4YzUuNDY5LTYuOTM4IDQuMjgxLTE3LTIuNjQxLTIyLjQ4TDI1LjkyIDMuNDIyeiIvPjwvc3ZnPg=='
const navigatorPossible = navigator.onLine

if (!navigatorPossible) {
  MySwal.fire({
    // eslint-disable-next-line no-template-curly-in-string
    html: `<img src='${navigatorOfflineIcon}' alt='icon_offline' style="width:60px; height: 60px"/><h1><strong>Sei Offline</strong></h1><p>Si chiudera aumentaticamente quando tornerai Online</p>`,
    showConfirmButton: false,
    allowOutsideClick: false
  })
}
window.ononline = (() => {
  // eslint-disable-next-line no-alert
  MySwal.fire({
    // eslint-disable-next-line no-template-curly-in-string
    icon: 'success',
    html: '<h2><strong>Sei Online</strong></h2>',
    showConfirmButton: true,
    allowOutsideClick: false
  })
})
window.onoffline = (() => {
  // eslint-disable-next-line no-alert
  MySwal.fire({
    // eslint-disable-next-line no-template-curly-in-string
    html: `<img src='${navigatorOfflineIcon}' alt='icon_offline' style="width:77px; height: 77px; margin:27px 27px"/><h2><strong>Sei Offline</strong></h2><p>Controlla la connesione!</p>`,
    showConfirmButton: false,
    allowOutsideClick: false
  })
})
window.addEventListener('unhandledrejection', function (event) {
  console.log('Unhandled promise rejection: ', event.promise, event.reason)
})

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityProvider>
          <ThemeContext>
            <LazyApp />
            <Toaster position={themeConfig.layout.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
          </ThemeContext>
        </AbilityProvider>
      </Suspense>
    </Provider>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
/* eslint-disable */
Object.defineProperty(String.prototype, 'capitalize', {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});
/* eslint-enable */
